import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ApiResponse, ConfigService, GenericService } from 'sumax-erp-schematics';
import { TbTipoContenedor } from '~models/maestros';
import { INgSelectObject } from '~shared/interface/INgSelectObject';

@Injectable({
	providedIn: 'root',
})
export class TbTipoContenedorService extends GenericService<TbTipoContenedor, number> {
	private _url = `${environment.HOST_MAESTROS_SYNC}/tbtipocontenedores`;

	constructor(protected _http: HttpClient) {
		super(_http);
	}

	get configuration(): ConfigService<number> {
		return {
			url: {
				base: this._url,
			},
			getPK(pk: number): string {
				return `${pk}`;
			},
		};
	}

	findAllByIdList(ids: number[]): Observable<TbTipoContenedor[]> {
		return this._http.post<ApiResponse<TbTipoContenedor[]>>(`${this._url}/findAllByIdList`, ids).pipe(map((res) => res.data));
	}

	findAllByEstado(estado: boolean): Observable<TbTipoContenedor[]> {
		return this._http.get<ApiResponse<TbTipoContenedor[]>>(`${this._url}/findAllByEstado/${estado}`).pipe(map((res) => res.data));
	}

	findAllNgSelectByEstado(estado: boolean): Observable<INgSelectObject<TbTipoContenedor>[]> {
		return this.findAllByEstado(estado).pipe(
			mergeMap((res) => {
				return of(
					res
						.map((e) => this.convertToNgSelect(e))
						.sort((a, b) => {
							const numeroA = this._extractNumber(a.descripcion ?? '');
							const numeroB = this._extractNumber(b.descripcion ?? '');
							return numeroA - numeroB;
						})
				);
			})
		);
	}

	convertToNgSelect(res: TbTipoContenedor): INgSelectObject<TbTipoContenedor> {
		return {
			...res,
			value: res.idTipoContenedor,
			label: `${res.descripcion} - ${res.tipo}`,
		};
	}

	private _extractNumber(descripcion: string): number {
		const match = descripcion.match(/^(\d+)/);
		return match ? parseInt(match[1], 10) : 0;
	}
}
