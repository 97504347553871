import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CellClassRules, CellEditingStoppedEvent, ColumnApi, GridApi, RowNode, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { TbConfiguracionAsignacionService } from 'app/services/maestros-sync/maestros';
import { TbUnidadMedidadService } from 'app/services/maestros-sync/maestros/tbUnidadMedida.service';
import { Observable, Subscription, of } from 'rxjs';
import { InputNumberComponent, NoRowsOverlayCustomComponent } from 'ngx-sumax-erp-component';
import { SacCotizacionDetalleBulto } from '~models/acuerdos-comerciales/sacCotizacionDetalleBulto';
import { BaseConfigEditor, InputNumberConfigEditor, NgSelectSimpleConfigEditor } from '~models/components/BaseConfigEditor';
import ColDefCustom from '~models/components/ColDefCustom';
import ICellEditorParamsCustom from '~models/components/ICellEditorParamsCustom';
import { TbUnidadMedida } from '~models/maestros';
import { FormGroupCompare } from '~shared/classes/FormGroupCompare';
import { InputNumberEditorComponent } from '~shared/components/cell-editors/input-number-editor/input-number-editor.component';
import { InputTextEditorComponent } from '~shared/components/cell-editors/input-text-editor/input-text-editor.component';
import { NgSelectSimpleEditorComponent } from '~shared/components/cell-editors/ng-select-simple-editor/ng-select-simple-editor.component';
import { EnumListaPersonalizada } from '~shared/enums/EnumListaPersonalizada';
import { INgSelect } from '~shared/interface/INgSelect';
import { INgSelectObject } from '~shared/interface/INgSelectObject';
import { NgSelectOption } from '~shared/interface/ngselect.interfaces';
import { MessageUtilService } from '~shared/services/message-util.service';
import { parseDecimalAndCommaCustom } from '~shared/utils/MathUtil';
import { DialogData } from '~shared/interface/DialogData';

@Component({
	selector: 'app-lista-dimensiones',
	templateUrl: './lista-dimensiones.component.html',
	styleUrls: ['./lista-dimensiones.component.scss'],
})
export class ListaDimensionesComponent implements OnInit, OnDestroy {
	@ViewChild('cantidadBulto') cantidadBultoElement!: InputNumberComponent;
	frameWorkComponents = {
		inputTextEditorComponent: InputTextEditorComponent,
		inputNumberEditorComponent: InputNumberEditorComponent,
		ngSelectSimpleEditorComponent: NgSelectSimpleEditorComponent,
		customNoRowsOverlay: NoRowsOverlayCustomComponent,
	};
	rowData: Observable<UntypedFormGroup[]> = of([]);
	gridApi?: GridApi;
	gridColumnApi?: ColumnApi;
	isDisabledAddRow = true;
	columnDefs: ColDefCustom<BaseConfigEditor>[] = [];
	isCargaSuelta = false;
	isValidGrilla = true;
	tbUnidadMedida$: Observable<NgSelectOption<TbUnidadMedida>[]> = of([]);

	private _formGroup!: UntypedFormGroup;
	private listSacCotizacionDetalleBultos: SacCotizacionDetalleBulto[] | undefined = [];

	private listObj: SacCotizacionDetalleBulto[] = [];

	private readonly _subscription$ = new Subscription();

	constructor(
		@Inject(MAT_DIALOG_DATA) dlgData: DialogData<SacCotizacionDetalleBulto[]>,
		public readonly dialogRef: MatDialogRef<ListaDimensionesComponent>,
		private readonly fb: UntypedFormBuilder,
		private readonly tbUnidadMedidadService: TbUnidadMedidadService,
		private readonly tbConfiguracionAsignacionService: TbConfiguracionAsignacionService,
		private readonly messageUtilService: MessageUtilService
	) {
		this.listSacCotizacionDetalleBultos = dlgData.data as SacCotizacionDetalleBulto[];
		this.isCargaSuelta = dlgData.object.isCargaSuelta as boolean;
		if (this.isCargaSuelta) this.tbUnidadMedida$ = this.tbConfiguracionAsignacionService.findAllUnidadMedidaByCodigoSistema(EnumListaPersonalizada.SACCOMUNIDADLONG);
		else this.tbUnidadMedida$ = this.tbConfiguracionAsignacionService.findAllUnidadMedidaByCodigoSistema(EnumListaPersonalizada.SACCOAUNIDADLONG);
		this.listObj = [...(this.listSacCotizacionDetalleBultos ?? [])];
	}
	ngOnDestroy(): void {
		this._subscription$.unsubscribe();
	}

	ngOnInit(): void {
		this._createForm();
		this._initAgGrid();
		this._initItems();
	}

	private _createForm(): void {
		this._formGroup = this.fb.group({
			cantidadBulto: [null, [Validators.required, Validators.min(1)]],
			largoBulto: [null, [Validators.required]],
			anchoBulto: [null, [Validators.required]],
			altoBulto: [null, [Validators.required]],
			tbUnidadMedida: [null, [Validators.required]],
			pesoBulto: [null],
		});
	}

	private _initAgGrid(): void {
		this.columnDefs = [
			{
				headerName: 'Bultos',
				colId: 'cantidadBulto',
				width: 145,
				valueGetter: (params: ValueGetterParams): number | null => (params.data ? ((params.data as UntypedFormGroup).get('cantidadBulto')?.value as number) : null),
				valueFormatter: (params: ValueFormatterParams): string => {
					if (params.value) {
						return parseDecimalAndCommaCustom((params.data as UntypedFormGroup).get('cantidadBulto')?.value, 0) || '';
					}
					return '';
				},
				cellClass: ['d-flex justify-content-end'],
				cellEditor: 'inputNumberEditorComponent',
				cellEditorParams: {
					configuration: {
						label: 'Bultos',
						decimals: 0,
						required: true,
					} as InputNumberConfigEditor,
					controlName: 'cantidadBulto',
				},
				pinned: 'left',
				editable: true,
				cellClassRules: this.getCellClassRules('cantidadBulto'),
			},
			{
				headerName: 'Largo',
				colId: 'largoBulto',
				width: 90,
				valueGetter: (params: ValueGetterParams): number | null => (params.data ? ((params.data as UntypedFormGroup).get('largoBulto')?.value as number) : null),
				valueFormatter: (params: ValueFormatterParams): string => {
					if (params.value) {
						return parseDecimalAndCommaCustom((params.data as UntypedFormGroup).get('largoBulto')?.value, 3) || '';
					}
					return '';
				},
				cellClass: ['d-flex justify-content-end'],
				cellEditor: 'inputNumberEditorComponent',
				cellEditorParams: {
					configuration: {
						label: 'Largo',
						decimals: 3,
					} as InputNumberConfigEditor,
					controlName: 'largoBulto',
				},
				pinned: 'left',
				editable: true,
			},
			{
				headerName: 'Ancho',
				colId: 'anchoBulto',
				width: 90,
				valueGetter: (params: ValueGetterParams): number | null => (params.data ? ((params.data as UntypedFormGroup).get('anchoBulto')?.value as number) : null),
				valueFormatter: (params: ValueFormatterParams): string => {
					if (params.value) {
						return parseDecimalAndCommaCustom((params.data as UntypedFormGroup).get('anchoBulto')?.value, 3) || '';
					}
					return '';
				},
				cellClass: ['d-flex justify-content-end'],
				cellEditor: 'inputNumberEditorComponent',
				cellEditorParams: {
					configuration: {
						label: 'Ancho',
						decimals: 3,
					} as InputNumberConfigEditor,
					controlName: 'anchoBulto',
				},
				pinned: 'left',
				editable: true,
			},
			{
				headerName: 'Alto',
				colId: 'altoBulto',
				width: 90,
				valueGetter: (params: ValueGetterParams): number | null => (params.data ? ((params.data as UntypedFormGroup).get('altoBulto')?.value as number) : null),
				valueFormatter: (params: ValueFormatterParams): string => {
					if (params.value) {
						return parseDecimalAndCommaCustom((params.data as UntypedFormGroup).get('altoBulto')?.value, 3) || '';
					}
					return '';
				},
				cellClass: ['d-flex justify-content-end'],
				cellEditor: 'inputNumberEditorComponent',
				cellEditorParams: {
					configuration: {
						label: 'Alto',
						decimals: 3,
					} as InputNumberConfigEditor,
					controlName: 'altoBulto',
				},
				pinned: 'left',
				editable: true,
			},
			{
				headerName: 'Unid.',
				colId: 'tbUnidadMedida',
				width: 160,
				valueGetter: (params: ValueGetterParams): INgSelectObject<TbUnidadMedida> | null => {
					const value = (params.data as UntypedFormGroup).get('tbUnidadMedida')?.value as TbUnidadMedida;
					return value ? this.tbUnidadMedidadService.convertToNgSelect(value) : null;
				},
				valueFormatter: (params: ValueFormatterParams): string => {
					return (params.value as INgSelect)?.label || '';
				},
				editable: true,
				cellEditor: 'ngSelectSimpleEditorComponent',
				cellEditorParams: (): ICellEditorParamsCustom<NgSelectSimpleConfigEditor> => {
					return {
						configuration: {
							label: 'Unid',
							items: this.tbUnidadMedida$,
						},
						controlName: 'tbUnidadMedida',
					};
				},
				pinned: 'left',
			},
			{
				headerName: 'Peso bulto',
				colId: 'pesoBulto',
				width: 120,
				valueGetter: (params: ValueGetterParams): number | null => (params.data ? ((params.data as UntypedFormGroup).get('pesoBulto')?.value as number) : null),
				valueFormatter: (params: ValueFormatterParams): string => {
					if (params.value) {
						return parseDecimalAndCommaCustom((params.data as UntypedFormGroup).get('pesoBulto')?.value, 3) || '';
					}
					return '';
				},
				cellClass: ['d-flex justify-content-end'],
				cellEditor: 'inputNumberEditorComponent',
				cellEditorParams: {
					configuration: {
						label: 'Peso bulto',
						decimals: 3,
					} as InputNumberConfigEditor,
					controlName: 'pesoBulto',
				},
				pinned: 'left',
				editable: true,
			},
			{
				headerName: 'Volumen',
				colId: 'volumen',
				width: 110,
				valueGetter: (params: ValueGetterParams): number | null => (params.data ? ((params.data as UntypedFormGroup).get('volumen')?.value as number) : null),
				valueFormatter: (params: ValueFormatterParams): string => {
					if (params.value) {
						return parseDecimalAndCommaCustom((params.data as UntypedFormGroup).get('volumen')?.value, 3) || '';
					}
					return '';
				},
				cellClass: ['d-flex justify-content-end', 'disabled-control'],
				cellEditor: 'inputNumberEditorComponent',
				cellEditorParams: {
					configuration: {
						label: 'Volumen',
						decimals: 3,
					} as InputNumberConfigEditor,
					controlName: 'volumen',
				},
				pinned: 'left',
				editable: false,
			},
			{
				headerName: 'Peso/Volu.',
				colId: 'pesoVolumetrico',
				width: 110,
				valueGetter: (params: ValueGetterParams): number | null => (params.data ? ((params.data as UntypedFormGroup).get('pesoVolumetrico')?.value as number) : null),
				valueFormatter: (params: ValueFormatterParams): string => {
					if (params.value) {
						return parseDecimalAndCommaCustom((params.data as UntypedFormGroup).get('pesoVolumetrico')?.value, 3) || '';
					}
					return '';
				},
				cellClass: ['d-flex justify-content-end', 'disabled-control'],
				cellEditor: 'inputNumberEditorComponent',
				cellEditorParams: {
					configuration: {
						label: 'Peso/Volu.',
						decimals: 3,
					} as InputNumberConfigEditor,
					controlName: 'pesoVolumetrico',
				},
				pinned: 'left',
				editable: false,
			},
			{
				headerName: 'Peso total',
				colId: 'pesoTotal',
				width: 120,
				valueGetter: (params: ValueGetterParams): number | null => (params.data ? ((params.data as UntypedFormGroup).get('pesoTotal')?.value as number) : null),
				valueFormatter: (params: ValueFormatterParams): string => {
					if (params.value) {
						return parseDecimalAndCommaCustom((params.data as UntypedFormGroup).get('pesoTotal')?.value, 3) || '';
					}
					return '';
				},
				cellClass: ['d-flex justify-content-end', 'disabled-control'],
				cellEditor: 'inputNumberEditorComponent',
				cellEditorParams: {
					configuration: {
						label: 'Peso total',
						decimals: 3,
					} as InputNumberConfigEditor,
					controlName: 'pesoTotal',
				},
				pinned: 'left',
				editable: false,
			},
		];
	}

	getCellClassRules(controlName: string): CellClassRules {
		return {
			'invalid-control': (params): boolean => {
				const control = (params.data as FormGroup).get(controlName);
				return control?.invalid ?? false;
			},
		};
	}

	private _initItems(): void {
		const forms = this.listSacCotizacionDetalleBultos?.map((e) => {
			const form = this._crearFormGrilla();
			this._llenarForm(form, e);
			return form;
		});
		if (forms) this.rowData = of(forms);
	}

	private _crearFormGrilla(): UntypedFormGroup {
		return this.fb.group({
			cantidadBulto: [null, [Validators.required, Validators.min(1)]],
			largoBulto: [null, [Validators.required]],
			anchoBulto: [null, [Validators.required]],
			altoBulto: [null, [Validators.required]],
			idUnidadMedida: [],
			tbUnidadMedida: [null, [Validators.required]],
			pesoBulto: [null],
			volumen: [null],
			pesoVolumetrico: [null],
			pesoTotal: [null],
		});
	}

	private _llenarForm(form: UntypedFormGroup, item: SacCotizacionDetalleBulto): void {
		if (item) {
			form.patchValue({
				...item,
				idUnidadMedida: item.tbUnidadMedida?.idUnidadMedida,
				tbUnidadMedida: item.tbUnidadMedida ? this.tbUnidadMedidadService.convertToNgSelect(item.tbUnidadMedida) : null,
				pesoTotal: Number(item.pesoBulto) * Number(item.cantidadBulto),
			});
		}
	}

	private _convertFormToSacCotizacionDetalleBulto(): SacCotizacionDetalleBulto {
		return this.formGroup.getRawValue() as SacCotizacionDetalleBulto;
	}

	private _convertRowNodeToForm(row: SacCotizacionDetalleBulto): UntypedFormGroup {
		const form = this._crearFormGrilla();
		this._llenarForm(form, row);
		return form;
	}

	private _convertFormToRowNode(form: UntypedFormGroup): SacCotizacionDetalleBulto {
		return form.getRawValue() as SacCotizacionDetalleBulto;
	}

	addRow(): void {
		if (this.formGroup.valid) {
			this.listSacCotizacionDetalleBultos = [];
			const sacCotizacionDetalleBulto = this._convertFormToSacCotizacionDetalleBulto();
			this.rowData.subscribe((res: UntypedFormGroup[]) => {
				res.forEach((e) => {
					const sacCotizacionDetalleBult = e.getRawValue() as SacCotizacionDetalleBulto;
					this.listSacCotizacionDetalleBultos?.push(sacCotizacionDetalleBult);
				});
				this.listSacCotizacionDetalleBultos?.push(sacCotizacionDetalleBulto);
				const forms = this.listSacCotizacionDetalleBultos?.map((e) => {
					const form = this._crearFormGrilla();
					this._calculate(form);
					this._llenarForm(form, e);
					return form;
				});
				if (forms) {
					this.formGroup.reset();
					setTimeout(() => {
						this.cantidadBultoElement.focus();
					}, 200);
					this.rowData = of(forms);
				}
			});
		}
	}

	deleteRow(rowNode: RowNode): void {
		this.listSacCotizacionDetalleBultos = [];
		this.rowData.subscribe((res: UntypedFormGroup[]) => {
			res.forEach((e) => {
				const sacCotizacionDetalleBulto = e.getRawValue() as SacCotizacionDetalleBulto;
				if (FormGroupCompare.isDifferentForm(rowNode.data.value, e.value)) {
					this.listSacCotizacionDetalleBultos?.push(sacCotizacionDetalleBulto);
				}
			});
			const forms = this.listSacCotizacionDetalleBultos?.map((e) => {
				const form = this._crearFormGrilla();
				this._llenarForm(form, e);
				return form;
			});
			if (forms) this.rowData = of(forms);
		});
	}

	cellEditingStopped(evt: CellEditingStoppedEvent): void {
		const sacCotizacionDetalleBulto = this._convertFormToRowNode(evt.data as UntypedFormGroup);

		if (this.listSacCotizacionDetalleBultos?.some((e) => FormGroupCompare.isDifferentForm(this._convertRowNodeToForm(e).value, evt.data.value))) {
			const rowIndex = evt.node.rowIndex ?? 0;
			const sacCotizacionDetalleBultoOld = this.listSacCotizacionDetalleBultos[rowIndex];
			const form = this._convertRowNodeToForm(sacCotizacionDetalleBultoOld);

			this.isValidGrilla = evt.data.status !== 'INVALID';

			if (FormGroupCompare.isDifferentForm(evt.data.value, form.value)) {
				this.isDisabledAddRow = false;
				this.listSacCotizacionDetalleBultos[rowIndex] = { ...sacCotizacionDetalleBulto };
				this._calculateGrilla(evt.data);
			} else this.isDisabledAddRow = true;
		}
	}

	private _calculate(form: UntypedFormGroup): void {
		const cantidadBultos = this.formGroup.get('cantidadBulto')?.value;
		const largoBulto = this.formGroup.get('largoBulto')?.value;
		const anchoBulto = this.formGroup.get('anchoBulto')?.value;
		const altoBulto = this.formGroup.get('altoBulto')?.value;
		const pesoBulto = this.formGroup.get('pesoBulto')?.value;

		let volumenTotal = 0;
		let pesoVolumetrico = 0;

		if (this.isCargaSuelta) {
			volumenTotal = cantidadBultos * (largoBulto * anchoBulto * altoBulto);
		} else {
			volumenTotal = cantidadBultos * ((largoBulto * anchoBulto * altoBulto) / 1000000);
			pesoVolumetrico = cantidadBultos * ((largoBulto * anchoBulto * altoBulto) / 6000);
		}
		form.get('volumen')?.setValue(volumenTotal);
		form.get('pesoVolumetrico')?.setValue(pesoVolumetrico);
		form.get('pesoTotal')?.setValue(Number(pesoBulto) * Number(cantidadBultos));
	}

	private _calculateGrilla(form: UntypedFormGroup): void {
		const cantidadBultos = form.get('cantidadBulto')?.value;
		const largoBulto = form.get('largoBulto')?.value;
		const anchoBulto = form.get('anchoBulto')?.value;
		const altoBulto = form.get('altoBulto')?.value;

		let volumenTotal = 0;
		let pesoVolumetrico = 0;

		if (this.isCargaSuelta) {
			volumenTotal = cantidadBultos * (largoBulto * anchoBulto * altoBulto);
			pesoVolumetrico = 0;
		} else {
			volumenTotal = cantidadBultos * ((largoBulto * anchoBulto * altoBulto) / 1000000);
			pesoVolumetrico = cantidadBultos * ((largoBulto * anchoBulto * altoBulto) / 6000);
		}

		form.get('volumen')?.setValue(volumenTotal);
		form.get('pesoVolumetrico')?.setValue(pesoVolumetrico);
		this.gridApi?.refreshCells({ columns: ['volumen'] });
		this.gridApi?.refreshCells({ columns: ['pesoVolumetrico'] });
	}

	save(): void {
		this.listSacCotizacionDetalleBultos = [];
		this.rowData.subscribe((res: UntypedFormGroup[]) => {
			res.forEach((e) => {
				const sacCotizacionDetalleBulto = e.getRawValue() as SacCotizacionDetalleBulto;
				this.listSacCotizacionDetalleBultos?.push(sacCotizacionDetalleBulto);
			});
			this.dialogRef.close(this.listSacCotizacionDetalleBultos);
		});
	}

	isDisabledBtnSave(): boolean {
		if (this.isValidGrilla) {
			if ((this.listSacCotizacionDetalleBultos?.length ?? 0) !== (this.listObj?.length ?? 0)) {
				return false;
			}

			return (
				this.listSacCotizacionDetalleBultos?.every((e, index) => {
					const form1 = this._convertRowNodeToForm(e).value;
					const form2 = this._convertRowNodeToForm(this.listObj[index]).value;
					return !FormGroupCompare.isDifferentForm(form1, form2);
				}) ?? false
			);
		} else return true;
	}

	onCancel(): void {
		if (this.isDisabledBtnSave()) this.dialogRef.close();
		else
			this.messageUtilService.getMessageQuestion('¿Desea cancelar el registro de dimensiones?', 'Los cambios realizados no se guardarán').then((res) => {
				if (res.value) this.dialogRef.close();
			});
	}

	onGridReady(gridApi: GridApi, gridColumnApi: ColumnApi): void {
		this.gridApi = gridApi;
		this.gridColumnApi = gridColumnApi;
	}

	get formGroup(): UntypedFormGroup {
		return this._formGroup;
	}
}
