import { ComponentType } from '@angular/cdk/portal';
import { OnDestroy, Component, Inject, PLATFORM_ID } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GridApi, IRowNode, RowNode } from 'ag-grid-community';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { EnumAccion } from '~shared/enums/EnumAccion';
import { MessageUtilService } from '~shared/services/message-util.service';
import { DialogData } from '~shared/interface/DialogData';
import { showLoading } from './LoadingUtil';

/* 
	parametros genericos 
	T ====> Clase o interface que se usa dentro del modal
	D ====> Nombre del componente modal a abrir 
*/
import { Injectable } from '@angular/core';
import { ConfigDialog } from './ModalUtil';

@Injectable({
	providedIn: 'root',
})
export class OpenModalUtil implements OnDestroy {
	public destroy$ = new Subject<void>();
	readonly messageUtilService = new MessageUtilService();
	constructor(protected _modal: MatDialog) {}

	/*
		Funcion que ejecuta el onCancel de los modales si existe sino solo cierra el modal
	 */
	public closeModal<D>(modalRef: MatDialogRef<D>): void {
		if (modalRef.componentInstance && typeof (modalRef.componentInstance as any).onCancel === 'function') {
			(modalRef.componentInstance as any).onCancel();
		} else {
			modalRef.close();
		}
	}
	/*
		Funcion para abrir otros modales personalizados
	*/
	public async openDialogCustom<D, E, R = any>(component: ComponentType<D>, dataDialog: DialogData<E> | unknown, configDialog: ConfigDialog): Promise<R | undefined> {
		const dialogRef = this._modal.open(component, {
			data: dataDialog,
			...configDialog,
			disableClose: true,
		});
		this.eventEscape(dialogRef);
		return await firstValueFrom(dialogRef.afterClosed());
	}

	/*
		Funcion para evento Escape
	*/
	public eventEscape<D>(maTDialogRef: MatDialogRef<D>): void {
		maTDialogRef
			.keydownEvents()
			.pipe(takeUntil(this.destroy$))
			.subscribe((resp) => {
				if (resp.code === 'Escape') {
					this.closeModal(maTDialogRef);
				}
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
