/* eslint-disable @typescript-eslint/unbound-method */
import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { ScaNroCotizacionDtoSearch } from '~models/dto/ScaNroCotizacionDtoSearch';
import { EnumTipoFiltroReferencia } from '~shared/enums/EnumTipoFiltro';

export interface SearchParameter {
	enumTipoFiltro?: EnumTipoFiltroReferencia;
	anio?: number | null;
	nroCotizacion?: number | null;
}

@Injectable({
	providedIn: 'root',
})
export class FiltroPorReferenciaService {
	constructor(private readonly _fb: UntypedFormBuilder) {}

	private readonly _changeTipoReferencia = new BehaviorSubject<string | null>(null);
	private readonly _onFilter = new BehaviorSubject<ScaNroCotizacionDtoSearch | null>(null);

	//formGroup Generico
	readonly formGenerico = this._fb.group({
		tipoReferencia: [null, [Validators.required]],
	});

	//formGroup DocumentoHouse
	readonly formDocumentoHouse = this._fb.group({
		tipoReferencia: [null, [Validators.required]],
		nroHouse: [null, [Validators.required]],
	});

	//formGroup NumeroReserva
	readonly formNroReserva = this._fb.group({
		tipoReferencia: [null, [Validators.required]],
		anio: [null, [Validators.required]],
		nroReserva: [null, [Validators.required]],
		tbViaTransporte: [null, [Validators.required]],
	});

	//formGroup NumeroReservaRouting
	readonly formNroReservaRouting = this._fb.group({
		tipoReferencia: [null, [Validators.required]],
		nroReserva: [null, [Validators.required]],
	});

	//formGroup NumeroContenedor
	readonly formNroContenedor = this._fb.group({
		tipoReferencia: [null, [Validators.required]],
		nroContenedor: [null, [Validators.required]],
	});

	//formGroup ManifiestoCarga
	readonly formManifiestoCarga = this._fb.group({
		tipoReferencia: [null, [Validators.required]],
		anio: [null, [Validators.required]],
		nroManifCarga: [null, [Validators.required]],
		tbViaTransporte: [null, [Validators.required]],
	});

	//formGroup NumeroRouting
	readonly formNroRouting = this._fb.group({
		tipoReferencia: [null, [Validators.required]],
		anio: [null, [Validators.required]],
		nroReferencia: [null, [Validators.required]],
	});

	//formGroup NumeroTicket
	readonly formNroTicket = this._fb.group({
		tipoReferencia: [null, [Validators.required]],
		anio: [null, [Validators.required]],
		nroTicket: [null, [Validators.required]],
	});

	//formGroup NumeroMaster
	readonly formNroMaster = this._fb.group({
		tipoReferencia: [null, [Validators.required]],
		nroMaster: [null, [Validators.required]],
	});

	// Obtener y pasar el change del Tipo de referencia del componente hijo al padre
	get changeTipoRefer(): Observable<string | null> {
		return this._changeTipoReferencia.asObservable();
	}

	changeTipoReferencias(e: string): void {
		this._changeTipoReferencia.next(e);
	}

	//Obtener evento consultar
	get onFilter(): Observable<ScaNroCotizacionDtoSearch | null> {
		return this._onFilter.asObservable();
	}

	filterToSearch(e: ScaNroCotizacionDtoSearch): void {
		this._onFilter.next(e);
	}

	public downloadBase64File(contentType: string, base64Data: any, fileName: string | null | undefined): void {
		try {
			if (!fileName) {
				// console.log(fileName);
				return;
			}
			const linkSource = `data:${contentType};base64,${base64Data?.trim()}`;
			const downloadLink = document.createElement('a');
			downloadLink.href = linkSource;
			downloadLink.download = fileName;
			downloadLink.click();
		} catch (error) {
			console.log(error);
		}
	}
}
