import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SacCotizacionService } from 'app/services/acuerdos-comerciales/sacCotizacion.service';
import { Observable, Subscription, forkJoin, map, of, switchMap, tap } from 'rxjs';
import { SacCotizacion } from '~models/acuerdos-comerciales/SacCotizacion';
import { DialogData } from '~shared/interface/DialogData';
import { convertToDecimal, parseDecimalAndCommaCustom } from '~shared/utils/MathUtil';
import { SacCotizacionDetalle } from '~models/acuerdos-comerciales/SacCotizacionDetalle';
import { SacCotizacionContenedor } from '~models/acuerdos-comerciales/SacCotizacionContenedor';
import { SacCotizacionConcepto, SacCotizacionObservacion } from '~models/acuerdos-comerciales';
import { SacCotizacionConceptoService } from 'app/services/acuerdos-comerciales/sacCotizacionConcepto.service';
import { TbGrupoConcepto } from '~models/maestros-sync/maestros/TbGrupoConcepto';
import { SciSistemaService } from 'app/services/maestros-sync/config/sciSistema.service';
import { TbGrupoConceptoService } from 'app/services/maestros-sync/maestros';
import { SacCotizacionDetalleBultoService } from 'app/services/acuerdos-comerciales/sacCotizacionDetalleBulto.service';
@Component({
	selector: 'app-ver-coti-resumen',
	templateUrl: './ver-coti-resumen.component.html',
	styleUrls: ['./ver-coti-resumen.component.scss'],
})
export class VerCotiResumenComponent implements OnDestroy {
	dataGrupoConceptos$: Observable<TbGrupoConcepto[]> = of([]);
	dataResumen$: Observable<SacCotizacion> = of();
	sacCotizacionDetalles$: Observable<SacCotizacionDetalle[]> = of();
	sacCotizacionContenedores$: Observable<SacCotizacionContenedor[]> = of();
	cotiEstadoss$: Observable<{ color: string; nombre: string }> = of();
	contactoCliente$: Observable<string> = of();
	totalBultos$: Observable<string> = of();
	totalPesoBruto$: Observable<string> = of();
	totalVolumen$: Observable<string> = of();
	resumenContenedor$: Observable<string> = of();
	scaObservaciones$: Observable<SacCotizacionObservacion[] | undefined> = of();
	contentTerminos$: Observable<any> = of();
	listTerminos$: Observable<string[]> = of();

	labelContenedorDimensiones = 'Contenedores';

	isViaTransporteAerea = false;
	isCargaSuelta = false;

	dataSacCotizacionConcepto!: SacCotizacionConcepto[];
	private readonly _subscription$ = new Subscription();

	idCotizacion!: number | null;
	coti!: SacCotizacion;
	dataGrupos!: TbGrupoConcepto;
	isLoading = true;

	constructor(
		@Inject(MAT_DIALOG_DATA) public dialogData: DialogData<number>,
		private readonly _sacCotizacionService: SacCotizacionService,
		private readonly _sacCotizacionConceptoService: SacCotizacionConceptoService,
		private readonly _sciSistemaService: SciSistemaService,
		private readonly _tbGrupoConceptoService: TbGrupoConceptoService,
		private readonly _sacCotizacionDetalleBultoService: SacCotizacionDetalleBultoService
	) {
		this.idCotizacion = this.dialogData.data;
		this._loadData();
	}

	private _loadData(): void {
		this.isLoading = true;

		const forkJoinObservable = forkJoin({
			resumen: this._sacCotizacionService.findById(this.idCotizacion).pipe(tap(() => (this.isLoading = false))),
			conceptos: this._sacCotizacionConceptoService.findAllByIdCotizacion(this.idCotizacion),
		});

		this._subscription$.add(
			forkJoinObservable.subscribe({
				next: ({ resumen, conceptos }) => {
					if (resumen.tbViaTransporte?.codigo == 'A') {
						this.isViaTransporteAerea = true;
						this.labelContenedorDimensiones = 'Dimensiones';
						this._setDimensiones(resumen);
					}
					if (resumen.tbViaTransporte?.codigo == 'M' && resumen.tbTipoMovimientoContenedor?.codigo == '2') {
						this.isCargaSuelta = true;
						this.labelContenedorDimensiones = 'Dimensiones';
						this._setDimensiones(resumen);
					}
					this.dataResumen$ = of(resumen);
					this.coti = resumen;
					this.dataSacCotizacionConcepto = conceptos;
					this.addFleteCargosResumen();
					this.resumenList();
				},
				error: () => (this.isLoading = false),
			})
		);
	}

	getDimensiones(cotizacionDetalle: SacCotizacionDetalle): string {
		let dimensiones = '';
		cotizacionDetalle.sacCotizacionDetalleBultos?.forEach((e) => {
			dimensiones = `${dimensiones}(${e.cantidadBulto})${e.largoBulto}X${e.anchoBulto}X${e.altoBulto} ${e.tbUnidadMedida?.nombre} `;
		});
		return dimensiones.trim();
	}

	addFleteCargosResumen(): void {
		if (this.idCotizacion) {
			if (this.coti.sacCotizacionTipoOperadores) {
				const listIdTipoOperador = this.coti.sacCotizacionTipoOperadores?.map((data) => data.idTipoOperador).filter((id): id is number => id !== undefined);
				this.dataGrupoConceptos$ = this._sciSistemaService.findAllByIdTipoOperadorList(listIdTipoOperador).pipe(
					map((data) => data.map((item) => item.idSistema).filter((id): id is number => id !== null)),
					switchMap((ids) =>
						this._tbGrupoConceptoService.findAllByIdSistemaList(ids).pipe(
							map((e) => {
								return e.map((data: TbGrupoConcepto) => {
									//const regex = /\s*\([^)]*\)/;
									const regex = /\s\([^)]{0,100}\)/g;
									const valRepetido = e.filter((item) => item.codigo === data.codigo && item.nombre?.replace(regex, '') === data.nombre?.replace(regex, ''));
									if (valRepetido.length > 1 && valRepetido.findIndex((f) => f.codigo === data.codigo) !== -1) {
										data.nombre = `${data.nombre} (${data.sciSistema?.titulo})`;
									}
									return data;
								});
							})
						)
					)
				);

				this._sacCotizacionConceptoService.findAllByIdCotizacion(this.dialogData.data).subscribe({
					next: (resp) => {
						this.dataSacCotizacionConcepto = resp;
					},
				});
			}
		}
	}

	private _setDimensiones(resumen: SacCotizacion): void {
		const idsDetalles = resumen.sacCotizacionDetalles?.map((e) => e.idCotizacionDetalle);
		if (idsDetalles) {
			this._sacCotizacionDetalleBultoService.findAllByIdCotizacion(idsDetalles).subscribe((res) => {
				resumen.sacCotizacionDetalles = resumen.sacCotizacionDetalles?.map((e) => {
					const detalleBultos = res.filter((n) => n.sacCotizacionDetalle?.idCotizacionDetalle === e.idCotizacionDetalle);
					if (detalleBultos) {
						e.sacCotizacionDetalleBultos = detalleBultos;
					}
					return e;
				});
			});
		}
	}

	resumenList(): void {
		this.sacCotizacionDetalles$ = this.dataResumen$.pipe(map((s) => s?.sacCotizacionDetalles ?? []));
		this.sacCotizacionContenedores$ = this.dataResumen$.pipe(map((s) => s?.sacCotizacionContenedores ?? []));

		this.cotiEstadoss$ = this.dataResumen$.pipe(
			map((data) => ({
				color: `${data?.sacTipoEstadoCotizacion?.color}`,
				nombre: `${data?.sacTipoEstadoCotizacion?.nombre}`,
			}))
		);

		this.contactoCliente$ = this.dataResumen$.pipe(
			map((data) => {
				if (data === null || data === undefined) return '';
				const object = data?.tbEntidadContactoCliente;
				return `${object?.nombre ?? ''} ${object?.apellido1 ?? ''} ${object?.apellido2 ?? ''}`;
			})
		);

		this.totalBultos$ = this.sacCotizacionDetalles$.pipe(
			map((series) => {
				return convertToDecimal(
					series.map((s) => s.cantidadBultos).reduce((acc, s) => acc + s, 0),
					0
				);
			})
		);

		this.totalPesoBruto$ = this.sacCotizacionDetalles$.pipe(
			map((series) => {
				return convertToDecimal(
					series.map((s) => s.pesoBruto).reduce((acc, s) => acc + s, 0),
					3
				);
			})
		);

		this.totalVolumen$ = this.sacCotizacionDetalles$.pipe(
			map((series) => {
				return convertToDecimal(
					series.map((s) => s.volumen).reduce((acc, s) => acc + s, 0),
					3
				);
			})
		);

		this.resumenContenedor$ = this.sacCotizacionContenedores$.pipe(
			map((listObj) => {
				const counts = listObj?.reduce((acc, c) => {
					const { tbTipoContenedor } = c;
					const description = tbTipoContenedor?.descripcion;

					if (description) {
						acc[description] = (acc[description] || 0) + c.cantidad;
					}

					return acc;
				}, {} as { [type: string]: number });
				const countsExtended = Object.keys(counts ?? {}).map((k) => {
					return { name: k, count: counts[k] };
				});
				let containersconcat = '';
				for (const { count, name } of countsExtended) {
					if (containersconcat === '') {
						containersconcat = `${count} x ${name}`;
					} else {
						containersconcat = `${containersconcat} <br> ${count} x ${name}`;
					}
				}
				return containersconcat;
			})
		);

		this.scaObservaciones$ = this.dataResumen$.pipe(map((s) => s?.sacCotizacionObservaciones));

		this.contentTerminos$ = this.scaObservaciones$.pipe(
			map((s) => {
				return s?.reduce((total, before) => {
					const tipo = before.tbTipoObservacion?.nombre;
					if (tipo) {
						total[tipo] = total[tipo] ?? [];
						total[tipo].push(before.observacion);
					}
					return total;
				}, {} as any);
			})
		);

		this.listTerminos$ = this.scaObservaciones$.pipe(
			map((data) => {
				const contentTerminos = data?.reduce((totalData, beforeData) => {
					const tipoObservacion = beforeData.tbTipoObservacion?.nombre;
					if (tipoObservacion) {
						totalData[tipoObservacion] = totalData[tipoObservacion] ?? [];
						totalData[tipoObservacion].push(beforeData.observacion);
					}
					return totalData;
				}, {} as any);
				return Object.keys(contentTerminos !== undefined ? contentTerminos : {});
			})
		);
	}

	convertToDecimal(value: string | number | null | undefined, decimals = 3): string {
		if (value) {
			let numericValue: number;

			if (typeof value === 'number') {
				numericValue = value;
			} else {
				numericValue = parseFloat(value);
			}

			if (isNaN(numericValue)) {
				numericValue = 0;
			}

			return numericValue.toLocaleString(undefined, {
				minimumFractionDigits: decimals,
				maximumFractionDigits: decimals,
			});
		}

		return '0';
	}

	downloadResumenOrden(): void {
		window.print();
	}

	parseDecimalAndCommaCustom(number: number, decimal = 3): string {
		return parseDecimalAndCommaCustom(number, decimal);
	}

	ngOnDestroy(): void {
		this._subscription$.unsubscribe();
	}
}
