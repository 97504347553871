<div class="callback" *ngIf="isLoading">
	<mat-spinner></mat-spinner>
</div>

<div class="app-actions-top">
	<button mat-dialog-close mat-icon-button color="accent" aria-label="Cerrar resumen">
		<mat-icon>arrow_back</mat-icon>
	</button>
	<button (click)="downloadResumenOrden()" mat-icon-button color="accent" aria-label="Descargar documento">
		<mat-icon>vertical_align_bottom</mat-icon>
	</button>
</div>

<div class="basic" *ngIf="dataResumen$ | async as SacCotizacion">
	<div>
		<div class="row div">
			<div class="col-12 p-0">
				<div class="ms-2 row mt-2">
					<div class="col-8">
						<h4 class="fw-bold sumax-label">Cotización N° {{ SacCotizacion?.nroCotizacion }}</h4>
					</div>
					<div class="col-4">
						<p class="fw-bold ps-2">Estado:</p>
					</div>
				</div>
			</div>
			<div class="col-12 p-0">
				<div class="ms-2 row mt-2">
					<div class="col-8">
						<p class="fw-bold colorDesc pt-2 pb-2">
							{{
								(SacCotizacion.tbTipoManifiesto?.nombre ? SacCotizacion.tbTipoManifiesto?.nombre : '') +
									' ' +
									(SacCotizacion.tbViaTransporte?.nombre ? SacCotizacion.tbViaTransporte?.nombre : '') +
									' ' +
									(SacCotizacion.tbTipoMovimientoContenedor?.nombre ? SacCotizacion.tbTipoMovimientoContenedor?.nombre : '')
							}}
						</p>
					</div>

					<div class="col-4" *ngIf="cotiEstadoss$ | async as dataEstado">
						<p [style]="{ 'background-color': dataEstado.color }" class="fw-bold colorDesc ps-2 pt-2 pb-2">{{ dataEstado.nombre }}</p>
					</div>
				</div>
			</div>
			<div class="col-8 p-0">
				<div class="ms-2 row mt-2">
					<div class="col-2">
						<p>Incoterm:</p>
						<p class="colorDesc">{{ SacCotizacion?.tbIncoterm?.codigo }}</p>
					</div>
					<div class="col-2">
						<p>Tipo de envío:</p>
						<p class="colorDesc">{{ SacCotizacion?.tbTipoEnvio?.nombre }}</p>
					</div>
					<div class="col-2">
						<p>Fch. registro:</p>
						<p class="colorDesc">{{ SacCotizacion?.fchCotizacion | date : 'dd/MM/yyyy' }}</p>
					</div>
				</div>
			</div>
		</div>

		<div class="row div mt-2 mb-2">
			<div class="col-12 title">
				<p class="ms-2">Información principal</p>
			</div>

			<div class="col-12 p-0">
				<div class="ms-2 row mt-2">
					<div class="col-4">
						<p class="sumax-label">Cliente</p>
						<p class="colorDesc">{{ SacCotizacion?.tbCliente?.tbEntidad?.razonSocial }}</p>
					</div>
					<div class="col-4">
						<p class="sumax-label">Contacto del Cliente (Atención):</p>
						<p class="colorDesc">{{ contactoCliente$ | async }}</p>
					</div>
					<div class="col-4">
						<p class="sumax-label">Ejecutivo de venta:</p>
						<p class="colorDesc">
							{{ SacCotizacion.tbEjecutivoVendedor?.tbPersona?.nombre ? SacCotizacion.tbEjecutivoVendedor?.tbPersona?.nombre + ' ' : '' }}
							{{ SacCotizacion.tbEjecutivoVendedor?.tbPersona?.apellido1 ? SacCotizacion.tbEjecutivoVendedor?.tbPersona?.apellido1 + ' ' : '' }}
							{{ SacCotizacion.tbEjecutivoVendedor?.tbPersona?.apellido2 ? SacCotizacion.tbEjecutivoVendedor?.tbPersona?.apellido2 : '' }}
						</p>
					</div>
				</div>

				<div class="ms-2 row mt-2">
					<div class="col-4">
						<p class="sumax-label">Referencia de cliente:</p>
						<p class="colorDesc">{{ SacCotizacion?.referenciaCliente }}</p>
					</div>
					<div class="col-2 me-4">
						<p class="sumax-label">Válido desde:</p>
						<p class="colorDesc">{{ SacCotizacion?.fchVigenciaDesde | date : 'dd/MM/yyyy' }}</p>
					</div>
					<div class="col-2 me-4">
						<p class="sumax-label">Válido hasta:</p>
						<p class="colorDesc">{{ SacCotizacion?.fchVigenciaHasta | date : 'dd/MM/yyyy' }}</p>
					</div>
				</div>
			</div>
			<div class="col-12 content mt-2">
				<p class="fw-bold ms-2">Servicio de transporte</p>
			</div>
			<div class="col-12 p-0">
				<div class="ms-2 row mt-2">
					<div class="col-4">
						<p class="sumax-label">Transportista</p>
						<p class="colorDesc">{{ SacCotizacion?.tbTransportista?.tbEntidad?.razonSocial }}</p>
					</div>
					<div class="col-4">
						<p class="sumax-label">Tiempo de tránsito:</p>
						<p class="colorDesc">{{ SacCotizacion?.tiempoTransito }}</p>
					</div>
					<div class="col-4">
						<p class="sumax-label">Frecuencia de salida:</p>
						<p class="colorDesc">{{ SacCotizacion?.frecuenciaSalida }}</p>
					</div>
				</div>
			</div>
			<div class="col-12 content mt-2">
				<p class="fw-bold ms-2">Ubicaciones</p>
			</div>
			<div class="col-12 p-0">
				<ng-container>
					<div class="ms-2 row mt-2">
						<div class="col-4">
							<p class="sumax-label">Puerto de origen (embarque):</p>
							<p class="colorDesc">
								{{ SacCotizacion?.tbUbicacionComercialEmbarque?.codigo ? SacCotizacion.tbUbicacionComercialEmbarque?.codigo + ' - ' : '' }}
								{{ SacCotizacion?.tbUbicacionComercialEmbarque?.nombre ? SacCotizacion.tbUbicacionComercialEmbarque?.nombre + ', ' : '' }}
								{{ SacCotizacion?.tbUbicacionComercialEmbarque?.tbPais?.nombre ? SacCotizacion.tbUbicacionComercialEmbarque?.tbPais?.nombre : '' }}
							</p>
						</div>
						<div class="col-4">
							<p class="sumax-label">Dirección para recojo:</p>
							<p class="colorDesc">{{ SacCotizacion?.direccionRecojo }}</p>
						</div>
						<div class="col-4">
							<p class="sumax-label">Fecha estimada de embarque (ETD):</p>
							<p class="colorDesc">{{ SacCotizacion?.fchEstimadaEmbarque | date : 'dd/MM/yyyy HH:mm' }}</p>
						</div>
					</div>

					<div class="ms-2 row mt-2">
						<div class="col-4">
							<p class="sumax-label">Puerto de destino (llegada):</p>
							<p class="colorDesc">
								{{ SacCotizacion?.tbUbicacionComercialLlegada?.codigo ? SacCotizacion.tbUbicacionComercialLlegada?.codigo + ' - ' : '' }}
								{{ SacCotizacion?.tbUbicacionComercialLlegada?.nombre ? SacCotizacion.tbUbicacionComercialLlegada?.nombre + ', ' : '' }}
								{{ SacCotizacion?.tbUbicacionComercialLlegada?.tbPais?.nombre ? SacCotizacion.tbUbicacionComercialLlegada?.tbPais?.nombre : '' }}
							</p>
						</div>
						<div class="col-4">
							<p class="sumax-label">Dirección para entrega:</p>
							<p class="colorDesc">{{ SacCotizacion?.direccionEntrega }}</p>
						</div>
						<div class="col-4">
							<p class="sumax-label">Fecha estimada de llegada (ETA):</p>
							<p class="colorDesc">{{ SacCotizacion?.fchEstimadaLlegadaDestino | date : 'dd/MM/yyyy HH:mm' }}</p>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</div>

	<div>
		<div>
			<div class="row div mt-2 mb-2">
				<div class="col-12 title">
					<p class="ms-2">Detalle de la carga</p>
				</div>
				<div class="row col-12 mt-3">
					<div class="col-12" *ngIf="sacCotizacionDetalles$ | async as sacCotizacionDetalles">
						<div *ngIf="sacCotizacionDetalles.length > 0; else templateEmpty">
							<table class="p-0" aria-label="Tabla Carga">
								<thead>
									<tr>
										<th scope="col" class="content fw-bold" style="width: 15%">
											<p>{{ labelContenedorDimensiones }}</p>
										</th>
										<th scope="col" class="content fw-bold"><p>Bultos</p></th>
										<th scope="col" class="content fw-bold" style="white-space: pre-wrap; word-break: break-word"><p>Resumen de mercancía</p></th>
										<th scope="col" class="content fw-bold"><p>Peso bruto</p></th>
										<th scope="col" class="content fw-bold"><p>Volumen</p></th>
									</tr>
								</thead>
								<tbody>
									<tr *ngIf="!(isViaTransporteAerea || isCargaSuelta)">
										<td [attr.rowspan]="sacCotizacionDetalles.length" style="width: 15%">
											<div [innerHTML]="resumenContenedor$ | async"></div>
										</td>
										<td class="text-end">
											{{ convertToDecimal(sacCotizacionDetalles[0]?.cantidadBultos, 0) }}
											<p class="p-0">{{ sacCotizacionDetalles[0]?.tbTipoBulto?.nombre }}</p>
										</td>
										<td>
											<p class="p-0" style="white-space: pre-wrap; word-break: break-word">{{ sacCotizacionDetalles[0]?.resumenMercancia }}</p>
										</td>
										<td class="text-end">
											{{ parseDecimalAndCommaCustom(sacCotizacionDetalles[0].pesoBruto ?? 0, 3) }}
											<p class="p-0">{{ sacCotizacionDetalles[0]?.tbUnidadMedidaPeso?.codigo }}</p>
										</td>
										<td class="text-end">
											{{ parseDecimalAndCommaCustom(sacCotizacionDetalles[0].volumen ?? 0, 3) }}
											<p class="p-0">{{ sacCotizacionDetalles[0]?.tbUnidadMedidaVolumen?.codigo }}</p>
										</td>
									</tr>
									<tr *ngIf="isViaTransporteAerea || isCargaSuelta">
										<td style="width: 15%">
											{{ getDimensiones(sacCotizacionDetalles[0]) }}
										</td>
										<td class="text-end">
											{{ convertToDecimal(sacCotizacionDetalles[0]?.cantidadBultos, 0) }}
											<p class="p-0">{{ sacCotizacionDetalles[0]?.tbTipoBulto?.nombre }}</p>
										</td>
										<td>
											<p class="p-0" style="white-space: pre-wrap; word-break: break-word">{{ sacCotizacionDetalles[0]?.resumenMercancia }}</p>
										</td>
										<td class="text-end">
											{{ parseDecimalAndCommaCustom(sacCotizacionDetalles[0].pesoBruto ?? 0, 3) }}
											<p class="p-0">{{ sacCotizacionDetalles[0]?.tbUnidadMedidaPeso?.codigo }}</p>
										</td>
										<td class="text-end">
											{{ parseDecimalAndCommaCustom(sacCotizacionDetalles[0].volumen ?? 0, 3) }}
											<p class="p-0">{{ sacCotizacionDetalles[0]?.tbUnidadMedidaVolumen?.codigo }}</p>
										</td>
									</tr>
									<tr *ngFor="let detalle of sacCotizacionDetalles.slice(1)">
										<td *ngIf="isViaTransporteAerea || isCargaSuelta">
											{{ getDimensiones(detalle) }}
										</td>
										<td>
											{{ convertToDecimal(detalle?.cantidadBultos, 0) }}
											<p class="p-0">{{ detalle?.tbTipoBulto?.nombre }}</p>
										</td>
										<td>
											<p class="p-0" style="white-space: pre-wrap; word-break: break-word">{{ detalle?.resumenMercancia }}</p>
										</td>
										<td class="text-end">
											{{ parseDecimalAndCommaCustom(detalle.pesoBruto ?? 0, 3) }}
											<p class="p-0">{{ detalle?.tbUnidadMedidaPeso?.codigo }}</p>
										</td>
										<td class="text-end">
											{{ parseDecimalAndCommaCustom(detalle.volumen ?? 0, 3) }}
											<p class="p-0">{{ detalle.tbUnidadMedidaVolumen?.codigo }}</p>
										</td>
									</tr>
									<tr>
										<td class="content2"></td>
										<td class="content2 text-end">
											<p>
												<strong> {{ totalBultos$ | async }}</strong>
											</p>
										</td>
										<td class="content2"></td>
										<td class="content2 text-end">
											<p>
												<strong> {{ totalPesoBruto$ | async }} </strong>
											</p>
										</td>
										<td class="content2 text-end">
											<p>
												<strong> {{ totalVolumen$ | async }} </strong>
											</p>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<ng-template #templateEmpty> </ng-template>
				</div>
			</div>

			<div class="row div mt-2">
				<div class="col-12 title">
					<p class="ms-2">Flete y otros cargos</p>
				</div>
				<div class="row col-12">
					<div class="col-12 mt-3">
						<app-resumen-lista-concepto
							*ngFor="let dataGrupo of dataGrupoConceptos$ | async"
							[dataGrupos]="dataGrupo"
							[dataSacCotizacionConcepto]="dataSacCotizacionConcepto"></app-resumen-lista-concepto>
					</div>
				</div>
			</div>

			<div class="row div mt-2">
				<div class="col-12 title">
					<p class="ms-2">Términos y condiciones</p>
				</div>

				<div class="row mt-2">
					<div class="col-12">
						<div>
							<ul>
								<li class="colorDesc" *ngFor="let detalle of SacCotizacion?.sacCotizacionCondicionServicios">{{ detalle?.tbCondicionServicio?.nombre }}</li>
							</ul>
						</div>
						<div>
							<div class="ps-4" *ngIf="listTerminos$ | async as listTermino">
								<div *ngFor="let detalle of listTermino">
									<p class="sumax-label">{{ detalle }}</p>
									<div *ngIf="contentTerminos$ | async as contentTermino">
										<div class="ps-2 d-flex flex-row" *ngFor="let content of contentTermino[detalle]">
											<span class="font-weight">-</span>
											<p [innerHTML]="content" class="colorDesc ps-4 pe-1"></p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
