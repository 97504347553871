import { formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ColDef, RowClickedEvent, ValueGetterParams } from 'ag-grid-community';
import { SacCotizacionEstadoService } from 'app/services/acuerdos-comerciales/sacCotizacionEstado.service';
import { Observable, of } from 'rxjs';
import { SacCotizacionEstadoCustom } from '~models/acuerdos-comerciales/custom/SacCotizacionEstadoCustom';
import { EnumEstadoCotizacion } from '~shared/enums/EnumEstadoCotizacion';

@Component({
	selector: 'app-historial-estado',
	templateUrl: './historial-estado.component.html',
	styles: `
	.multiline-label {
		width: 64ch;
		word-wrap: break-word; /*Ajusta las palabras largas*/
		overflow-wrap: break-word; /* Evita que el texto se salga del contenedor */
		white-space: normal; /* Permite saltos de línea */
		color: black;
	}`,
})
export class HistorialEstadoComponent implements OnInit {
	rowData: Observable<SacCotizacionEstadoCustom[]> = of([]);

	columnDefs: ColDef[] = [];
	nroCotizacion: UntypedFormControl = new UntypedFormControl('');

	observacion: { isRechazo: boolean | false; motivoEstado: string; observacion: string };

	constructor(
		@Inject(LOCALE_ID) private locale: string,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<HistorialEstadoComponent>,
		private readonly _cotizacionEstadoService: SacCotizacionEstadoService
	) {}

	ngOnInit(): void {
		this.createColumnDefs();
		this._cotizacionEstadoService.findAllByIdCotizacionCustom(this.data.idCotizacion).subscribe({
			next: (resp) => {
				this.rowData = of(resp);
				const estadoRechazado = resp.find((data) => data.codigo === EnumEstadoCotizacion.RECHAZADA);
				if (estadoRechazado) {
					this.observacion = {
						isRechazo: true,
						motivoEstado: estadoRechazado.motivoEstado,
						observacion: estadoRechazado.observacion,
					};
				}
			},
		});
		this.nroCotizacion.patchValue(this.data.nroCotizacion);
		this.nroCotizacion.disable();
	}

	createColumnDefs(): void {
		this.columnDefs = [
			{
				headerName: 'Estado',
				field: 'estado',
				width: 150,
			},
			{
				headerName: 'Fecha y Hora',
				field: 'fchRegistro',
				width: 150,
				valueGetter: (params: ValueGetterParams): string => {
					return params.data.fchRegistro ? formatDate(params.data.fchRegistro, 'dd/MM/yyyy hh:mm', this.locale) : '';
				},
			},
			{
				headerName: 'Usuario',
				field: 'usuario',
				width: 260,
			},
		];
	}

	close(): void {
		this.dialogRef.close();
	}
}
