/* src/app/modules/gestion/common/ver-coti-resumen/ver-coti-resumen.component.scss */
.basic {
  margin: auto;
  padding: 2em;
  width: 100%;
  box-shadow: 0 0 10px 2px #959595;
}
.basic .div {
  padding: 0 2em;
}
.title {
  background-color: #646c9a !important;
}
.title p {
  color: #ffffff;
  padding: 0.5rem 0rem;
  font-size: 1.1em;
}
.title p small {
  text-transform: capitalize !important;
}
.content {
  background-color: #f7f8fa !important;
}
.content p {
  color: #646c9a;
  padding: 0.5rem 0rem;
}
.content2 {
  background-color: #f7f8fa !important;
}
.content2 p {
  color: #646c9a;
}
.green {
  background-color: rgb(130, 201, 130) !important;
}
p {
  margin: 0px;
  padding: 0px;
}
table.table thead {
  background-color: #f7f8fa !important;
  color: #646c9a;
  padding: 0.5rem 0rem;
  font: bold !important;
}
table.table tbody {
  color: #4a4a4a;
}
table.table td {
  border: black 1px solid;
}
table.table tr {
  border: black 1px solid;
}
table.table th {
  border: black 1px solid;
}
table thead th,
table thead td {
  font-weight: 500;
  border-bottom-width: 1px;
  padding-top: 5px;
  padding-bottom: 5px;
}
table th,
table td {
  padding: 3px 4px 3px 15px;
  vertical-align: top;
  border-top: 1px solid #ebedf2;
}
table {
  width: 100%;
  border: 1px solid #d3d3d3;
}
table tbody {
  background-color: white;
  color: black;
}
table tbody td {
  padding: 0.75rem;
  vertical-align: middle;
}
.two {
  list-style-position: inside;
  list-style-type: none;
}
.mat-expansion-panel-header {
  background-color: #f7f8fa !important;
}
.colorDesc {
  color: #4a4a4a;
  text-align: justify;
  white-space: pre-line;
}
.callback {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.app-actions-top,
.app-actions-buton {
  position: fixed;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
  padding: 0.4rem 1rem;
}
.app-actions-top button,
.app-actions-buton button {
  color: #fff;
}
.app-actions-top {
  left: 0;
  top: 0;
  width: 100%;
}
.app-actions-buton {
  right: 0;
  bottom: 0;
  width: 10em;
}
/*# sourceMappingURL=ver-coti-resumen.component.css.map */
