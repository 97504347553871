import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { AppState } from '@core/store';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, EMPTY, switchMap, tap } from 'rxjs';
import { ElementsEnlace } from './redirect-utils.service';
import { SciAccesosService } from 'app/services/config/sciAccesos.service';
import { oficinaSelected } from '@core/store/selectors/oficinas.selectors';
import { EnumSistema } from '~shared/enums/EnumSistema';
import { EnumForm } from '~shared/enums/EnumForm';
import { SciFormularioAccionListado } from '@core/store/models/SciFormularioAccionListado';
import { EnumPermissionDefault } from '~shared/enums/EnumPermission';
import { environment } from 'environments/environment';

interface EnlacesRedirecciones {
	contratoTransporte: ElementsEnlace;
}
export type KeyEnlacesRedireccionesCarga = keyof EnlacesRedirecciones;

const initState: EnlacesRedirecciones = {
	contratoTransporte: {
		title: 'Contrato de transporte',
		isDisabled: false,
		url: `${environment.URL_CARGA}/control/contrato-transporte`,
	},
};

@Injectable({ providedIn: 'root' })
export class EnlacesRedireccionCargaStore extends ComponentStore<EnlacesRedirecciones> {
	constructor(private readonly _sciaccesosService: SciAccesosService, private readonly _store: Store<AppState>) {
		super(initState);
	}

	readonly loadState = this.effect(() => {
		return this._store.select(oficinaSelected).pipe(
			distinctUntilChanged((prev, actual) => prev?.idOficina === actual?.idOficina),
			switchMap((oficina) => {
				if (!oficina?.idOficina || isNaN(Number(oficina?.idOficina))) return EMPTY;
				return this._sciaccesosService.findAllByCodigoSistemaAndIdOficina(EnumSistema.CARGA, Number(oficina?.idOficina));
			}),
			tap((formularioAcciones: SciFormularioAccionListado[]) => {
				this.listEnlaceRegireccion.contratoTransporte.isDisabled = this.validAccesoFormulario(formularioAcciones, EnumForm.ContratoTransporte);
			})
		);
	});

	get listEnlaceRegireccion(): EnlacesRedirecciones {
		return this.get();
	}

	validAccesoFormulario(formularioAcciones: SciFormularioAccionListado[], enumForm: EnumForm): boolean {
		return !formularioAcciones.find((formAccion) => formAccion.codigo === enumForm)?.acciones.some((accion) => accion.codigo === EnumPermissionDefault.Listar);
	}
}
