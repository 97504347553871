import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, BehaviorSubject, filter } from 'rxjs';
import { SacCotizacion } from '~models/acuerdos-comerciales/SacCotizacion';
import { EnumTitle } from '~shared/enums/EnumTitle';
import { StepType } from '../registro-cotizacion.service';
import { SacCotizacionService } from 'app/services/acuerdos-comerciales/sacCotizacion.service';
import { SacCotizacionConcepto } from '~models/acuerdos-comerciales';
import { SacCotizacionOperativa } from '~models/acuerdos-comerciales/sacCotizacionOperativa';
import { SacTipoEstadoCotizacion } from '~models/maestros-sync/maestros/SacTipoEstadoCotizacion';
import { VariableGlobalCotizacion } from '../../cotizacion-constantes.service';

export interface CotizacionCrudState {
	sacCotizacion: SacCotizacion | null;
	loading: boolean;
	resourceToSaeSacCotizacion?: SacCotizacion | null;
	scaTipoEstadoCotizacion: SacTipoEstadoCotizacion | null;
	sacCotizacionFromResumen: SacCotizacion | null;
	sacCotizacionFleteCargo: SacCotizacion | null;
	sacCotizacionConcepto: SacCotizacionConcepto[] | null;
	sacCotizacionOperativa: SacCotizacionOperativa | null;
	refreshListGrupos: boolean;
	isEstadoEnviado: boolean;
	variableGlobalCotizacion: VariableGlobalCotizacion | null;
}

export const initialState: CotizacionCrudState = {
	sacCotizacion: null,
	loading: true,
	resourceToSaeSacCotizacion: null,
	scaTipoEstadoCotizacion: null,
	sacCotizacionFromResumen: null,
	sacCotizacionFleteCargo: null,
	sacCotizacionConcepto: null,
	sacCotizacionOperativa: null,
	refreshListGrupos: true,
	isEstadoEnviado: false,
	variableGlobalCotizacion: null,
};

export type StepNumber = 1 | 2 | 3 | 4 | 5 | 6;

@Injectable()
export class CotizacionStore extends ComponentStore<CotizacionCrudState> {
	private readonly _step$ = new BehaviorSubject<StepType>({
		step: 1,
		next: true,
	});
	readonly step$ = this._step$.asObservable();
	constructor(private readonly sacCotizacionService: SacCotizacionService) {
		super(initialState);
	}

	readonly sacCotizacion$: Observable<SacCotizacion | null> = this.select((state) => state.sacCotizacion);
	readonly loading$: Observable<boolean> = this.select((state) => state.loading);

	readonly setSacCotizacion = this.updater((state, sacCotizacion: SacCotizacion) => ({ ...state, sacCotizacion }));
	readonly setSacCotizacionEstado = this.updater((state, scaTipoEstadoCotizacion: SacTipoEstadoCotizacion) => ({ ...state, scaTipoEstadoCotizacion }));
	readonly getSacCotizacionEstado$ = this.select((state) => state.scaTipoEstadoCotizacion);
	readonly setLoading = this.updater((state, loading: boolean) => ({ ...state, loading }));
	readonly getScaCotizacions$ = this.select((state) => state.sacCotizacion);
	readonly setResourceToSaveSacCotizacion = this.updater((state, newResource: Partial<SacCotizacion>) => ({
		...state,
		resourceToSaeSacCotizacion: {
			...state.resourceToSaeSacCotizacion,
			...newResource,
		},
	}));
	readonly setVariableGlobalCotizacion = this.updater((state, variableGlobalCotizacion: VariableGlobalCotizacion) => ({ ...state, variableGlobalCotizacion }));
	readonly getResourceToSaveSacCotizacion$ = this.select((state) => state.resourceToSaeSacCotizacion);
	readonly sacCotizacionFromResumen = this.updater((state, sacCotizacionFromResumen: SacCotizacion) => ({ ...state, sacCotizacionFromResumen }));
	readonly setSacCotizacionFleteCargo = this.updater((state, sacCotizacionFleteCargo: SacCotizacion) => ({ ...state, sacCotizacionFleteCargo }));
	readonly getSacCotizacionFleteCargo$ = this.select((state) => state.sacCotizacionFleteCargo);
	readonly setSacCotizacionConcepto = this.updater((state, sacCotizacionConcepto: SacCotizacionConcepto[]) => ({ ...state, sacCotizacionConcepto }));
	readonly getSacCotizacionConcepto$ = this.select((state) => state.sacCotizacionConcepto);
	readonly setSacCotizacionOperativa = this.updater((state, sacCotizacionOperativa: SacCotizacionOperativa) => ({ ...state, sacCotizacionOperativa }));
	readonly getSacCotizacionOperativa$ = this.select((state) => state.sacCotizacionOperativa);
	readonly getVariableGlobalCotizacion$ = this.select((state) => state.variableGlobalCotizacion);

	//para validar el estado enviado de una cotizacion
	readonly setEstadoCotizacionEnviado = this.updater((state, isEstadoEnviado: boolean) => ({ ...state, isEstadoEnviado }));

	getIsEstadoEnviado(): boolean {
		return this.get().isEstadoEnviado;
	}

	//para refrescar lista de grupos
	readonly refreshListGrupos = this.updater((state, refreshListGrupos: boolean) => ({ ...state, refreshListGrupos }));
	readonly getRefreshListGrupos$ = this.select((state) => state.refreshListGrupos);
	// obtiene el objeto para guargar en las tablas
	getSacCotizacionToSave(): SacCotizacion | null | undefined {
		return this.get().resourceToSaeSacCotizacion;
	}

	getSacCotizacion(): SacCotizacion | null {
		return this.get().sacCotizacion;
	}
	getLoading(): boolean {
		return this.get().loading;
	}

	seeStep$(...steps: StepNumber[]): Observable<StepType> {
		return this.step$.pipe(filter(({ step }) => steps.includes(step)));
	}

	/** métodos adicionales */
	getTituloForModif(sacCotizacion?: SacCotizacion): string {
		const titulo = `${EnumTitle.Cotizaciones} N° ${sacCotizacion?.nroCotizacion} | ${sacCotizacion?.tbTipoManifiesto?.nombre} ${sacCotizacion?.tbViaTransporte?.nombre} ${
			sacCotizacion?.tbTipoMovimientoContenedor?.nombre ?? ''
		}`;
		return sacCotizacion ? titulo : EnumTitle.Cotizaciones;
	}

	//obtener data para pintar resumen
	getSacCotizacionFromResumen(): SacCotizacion | null | undefined {
		return this.get().sacCotizacionFromResumen;
	}

	getSacCotizacionFleteCargo(): SacCotizacion | null {
		return this.get().sacCotizacionFleteCargo;
	}

	getVariableGlobalCotizacion(): VariableGlobalCotizacion | null {
		return this.get().variableGlobalCotizacion;
	}
}
