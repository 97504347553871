<kt-portlet>
	<kt-portlet-header>
		<ng-container ktPortletTitle>
			<div class="me-3">
				<em class="fa fa-undo fa-1x"></em>
			</div>
			<h3 class="kt-portlet__head-title py-1 me-1">
				<strong>Historial de Estados</strong>
				&nbsp;<small>Listado</small>
			</h3>
		</ng-container>
	</kt-portlet-header>
	<kt-portlet-body [modalScroll]="true">
		<div class="row">
			<div class="col-lg-5 col-sm-4 mb-2 pe-0">
				<input-text [formControl]="nroCotizacion" label="Número de cotización" [readonly]="true"> </input-text>
			</div>
		</div>

		<sc-ag-grid-customizable
			style="width: 100%"
			id="grid"
			class="ag-theme-balham"
			[height]="'autoHeight'"
			[domLayout]="'autoHeight'"
			[columnDefs]="columnDefs"
			[rowData]="rowData"
			[btnAdd]="{ visible: false }"
			[pagination]="false"
			[visibleSearch]="false">
		</sc-ag-grid-customizable>
		@if (observacion && observacion.isRechazo) {
		<ng-container>
			<div style="margin-top: 10px">Observación</div>
			<fieldset class="fieldset-border fieldset-focus rounded">
				<div style="color: black; font-weight: 600; margin-top: 10px">{{ observacion.motivoEstado }}</div>
				<div class="multiline-label">{{ observacion.observacion }}</div>
			</fieldset>
		</ng-container>
		}
	</kt-portlet-body>
	<div style="margin-bottom: 20px !important"></div>
</kt-portlet>
