import { Injectable } from '@angular/core';
import { AppState } from '@core/store';
import { selectNroDocumento } from '@core/store/selectors/auth.selectors';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { SacCotizacion } from '~models/acuerdos-comerciales/SacCotizacion';
import { events, EventsChannel } from 'aws-amplify/api';
import { EnumChanelAppSync } from '~shared/enums/EnumChanelAppSync';

export interface ResponseAppSync {
	event: [];
	id: string;
	data: string;
}

@Injectable({
	providedIn: 'root',
})
export class NotificationService {
	nroDocumento!: string;
	newNotification$: Subject<SacCotizacion> = new Subject();

	constructor(private store: Store<AppState>) {
		this.store
			.select(selectNroDocumento)
			.pipe(take(1))
			.subscribe((nroDocumento) => {
				this.nroDocumento = String(nroDocumento);
			});

		this.getNewNotification();
	}

	// getUpdatedCotizacionList(): Observable<[IMqttMessage, IMqttMessage]> {
	// 	return combineLatest([this.mqttService.observe(this.nroDocumento + '- ListadoCotizacion-Estado'), this.mqttService.observe(this.nroDocumento + '- ListadoCotizacion-TipoOperador')]);
	// }

	getNewNotification(): void {
		this.getObjectAppSync(EnumChanelAppSync.RegistroCotizacion).subscribe((resp) => {
			if (resp) {
				const data = resp as SacCotizacion;
				const audio = new Audio('./assets/audio/notification.mp3');
				audio.play();
				this.newNotification$.next(data);
			}
		});
		// this.mqttService.observe(this.nroDocumento + '- RegistroCotizacion').subscribe((data) => {
		// 	const dataResponseMqtt: SacCotizacion = JSON.parse(data.payload.toString()) as SacCotizacion;
		// 	const audio = new Audio('./assets/audio/notification.mp3');
		// 	audio.play();
		// 	const resp = audio.play();
		// 	if (resp !== undefined) {
		// 		resp.catch((error) => {
		// 			// eslint-disable-next-line no-console
		// 			console.error(error);
		// 		});
		// 	}
		// 	this.newNotification$.next(dataResponseMqtt);
		// });
	}

	getObjectAppSync(chanel: string): Observable<unknown[]> {
		const channelName = `/default/${chanel}/${this.nroDocumento}`;
		//retornar un observable
		return new Observable<EventsChannel>((observer) => {
			events
				.connect(channelName)
				.then((channel) => {
					// El 'channel' es el objeto de tipo EventsChannel
					observer.next(channel);
					// Suscripción al canal
					channel.subscribe(observer);
				})
				.catch((error) => {
					observer.error(error);
				});
		}).pipe(map((data) => (data as unknown as ResponseAppSync).event));
	}
}
