<kt-portlet autocomplete="off">
	<kt-portlet-header>
		<ng-container ktPortletTitle>
			<div class="me-3">
				<em class="fa fa-briefcase fa-2x"></em>
			</div>
			<h3 class="kt-portlet__head-title py-2 me-2">
				<strong>Cotización</strong>
			</h3>
			<span class="small pt-2 ps-1"> Solicitud </span>
		</ng-container>
	</kt-portlet-header>
	<kt-portlet-body [modalScroll]="true">
		<div class="container" [formGroup]="formGroup">
			<fieldset class="fieldset-border fieldset-focus rounded">
				<legend class="fieldset-border">Datos principales</legend>
				<div class="row mt-0">
					<div class="col-lg-3">
						<label for="tbTipoOperadores" class="d-block sumax-label mt-0 mb-0">Tipo de operador *</label>
						<mat-selection-list
							#tbTipoOperadores
							[ngClass]="tbTipoOperadoresStyle"
							formControlName="tbTipoOperadores"
							[required]="true"
							(ngModelChange)="onChangeTipoFormato($event)"
							(keyup.arrowright)="tbTipoManifiesto.focus()"
							aria-labelledby="tbTipoOperadores">
							<ng-container *ngIf="tbTipoOperador$ | async as data">
								<mat-list-option *ngFor="let item of (tbTipoOperador$ | async) ?? data" [value]="item">
									<span style="font-size: 12px; margin-top: 0; margin-bottom: 0">{{ item?.label }}</span>
								</mat-list-option>
							</ng-container>
						</mat-selection-list>
						<small *ngIf="isBand" for="tbTipoOperadores" class="mb-0 text-danger">{{ tipoOperadorTextValid }}</small>
					</div>
					<div class="col-lg-9">
						<div class="row">
							<div class="col-lg-4">
								<ng-select-simple
									#tbTipoManifiesto
									[items]="tbTipoManifiesto$"
									[virtualScroll]="false"
									formControlName="tbTipoManifiesto"
									[required]="true"
									[next]="tbViaTransporte"
									(keyup.arrowleft)="tbTipoOperadoresElement.focus()"
									label="Tipo de operación">
								</ng-select-simple>
							</div>
							<div class="col-lg-4">
								<ng-select-simple
									#tbViaTransporte
									[items]="tbViaTransporte$"
									[virtualScroll]="false"
									formControlName="tbViaTransporte"
									[required]="true"
									[next]="tbTipoMovimientoContenedor"
									[nextIsDisable]="tbVersionIncoterm"
									[back]="tbTipoManifiesto"
									label="Vía de transporte">
								</ng-select-simple>
							</div>
							<div class="col-lg-4">
								<ng-select-simple
									#tbTipoMovimientoContenedor
									[items]="tbTipoMovimientoContenedor$"
									[virtualScroll]="false"
									formControlName="tbTipoMovimientoContenedor"
									[required]="formGroup.get('tbViaTransporte')?.value?.codigo !== 'A'"
									[disabled]="formGroup.get('tbViaTransporte')?.value?.codigo === 'A'"
									[notFoundText]="notFoundText"
									[next]="tbVersionIncoterm"
									[back]="tbViaTransporte"
									label="Tipo de flete">
								</ng-select-simple>
							</div>
						</div>
						<div class="row mt-2">
							<div class="col-lg-4">
								<ng-select-simple
									#tbVersionIncoterm
									[items]="tbVersionIncoterm$"
									[virtualScroll]="false"
									formControlName="tbVersionIncoterm"
									[clearable]="true"
									[next]="tbIncoterm"
									[back]="tbTipoMovimientoContenedor"
									[backIsDisable]="tbViaTransporte"
									label="Año (versión)">
								</ng-select-simple>
							</div>
							<div class="col-lg-4">
								<ng-select-simple
									#tbIncoterm
									[items]="tbIncoterm$"
									[virtualScroll]="false"
									formControlName="tbIncoterm"
									[notFoundText]="foundTextTbVersionIncoterm"
									[next]="tbTipoEnvio"
									[back]="tbVersionIncoterm"
									[clearable]="true"
									[required]="formGroup.get('tbVersionIncoterm')?.value"
									label="Incoterm">
								</ng-select-simple>
							</div>
							<div class="col-lg-4">
								<ng-select-simple
									#tbTipoEnvio
									[items]="tbTipoEnvio$"
									[virtualScroll]="false"
									formControlName="tbTipoEnvio"
									[clearable]="true"
									[next]="tbCliente"
									[back]="tbIncoterm"
									label="Tipo de envío">
								</ng-select-simple>
							</div>
						</div>
					</div>
				</div>
				<div class="row mt-2">
					<div class="col-lg-6">
						<div class="row me-0">
							<div class="col-sm-12 pe-0">
								<ng-select-simple
									#tbCliente
									[items]="tbCliente$"
									[virtualScroll]="false"
									formControlName="tbCliente"
									[required]="true"
									[refresh]="true"
									(onrefresh)="refreshCliente()"
									[next]="contactocliente"
									[back]="tbTipoEnvio"
									label="Cliente"
									[redirect]="true"
									[urlRedirect]="onRedirectMaestros('cliente').url!"
									[titleRedirect]="onRedirectMaestros('cliente').title"
									[isRedirectDisabled]="onRedirectMaestros('cliente').isDisabled">
								</ng-select-simple>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<div class="d-flex align-items-start">
							<div class="container-select-ingreso">
								<ng-select-simple
									#contactocliente
									[items]="tbContactoCliente$"
									formControlName="tbEntidadContacto"
									[virtualScroll]="false"
									[refresh]="true"
									(onrefresh)="refreshContactoCliente()"
									[clearable]="true"
									[notFoundText]="notFoundTextTbContactoCliente"
									[next]="tbUbicacionComercialEmbarque"
									[back]="tbCliente"
									label="Contacto de cliente (Atención)">
								</ng-select-simple>
							</div>

							<div class="container-button">
								<sc-button size="sm" color="success" height="31.5px" width="34px" (onClick)="onAddContactoCliente()" [disabled]="!isClienteSelected()">
									<em class="fa fa-plus px-1" [matTooltip]="'Agregar Contacto'" matTooltipClass="bg-success"></em>
								</sc-button>
							</div>

							<div class="container-button">
								<sc-button size="sm" color="warning" [disabled]="!formGroup.get('tbEntidadContacto')?.value" [fill]="true" height="31.5px" width="34px" (onClick)="descripcionCtcCliente()">
									<em class="fa fa-user px-1" [matTooltip]="'Ver contacto'" matTooltipClass="bg-warning"></em>
								</sc-button>
							</div>
						</div>
					</div>
				</div>
			</fieldset>
			<fieldset class="fieldset-border fieldset-focus rounded">
				<legend class="fieldset-border">Ubicaciones</legend>
				<div class="row">
					<div class="col-lg-6">
						<ng-select-simple
							#tbUbicacionComercialEmbarque
							formControlName="tbUbicacionComercialEmbarque"
							(onsearch)="setValueNgSelectPuertoEmbarque($event)"
							[items]="tbUbicacionComercialEmbarque$"
							[notFoundText]="foundTextTbUbicacionComercialEmbarque"
							[virtualScroll]="false"
							[upperCase]="true"
							[required]="true"
							[next]="direccionRecojo"
							[nextIsDisable]="tbUbicacionComercialLlegada"
							[back]="contactocliente"
							label="{{ labelPuertoOrigen }}">
						</ng-select-simple>
					</div>
					<div class="col-lg-6">
						<input-text #direccionRecojo formControlName="direccionRecojo" [next]="tbUbicacionComercialLlegada" [back]="tbUbicacionComercialEmbarque" label="Dirección para recojo"> </input-text>
					</div>
				</div>
				<div class="row mt-2">
					<div class="col-lg-6">
						<ng-select-simple
							#tbUbicacionComercialLlegada
							formControlName="tbUbicacionComercialLlegada"
							(onsearch)="setValueNgSelectPuertoLlegada($event)"
							[items]="tbUbicacionComercialLlegada$"
							[notFoundText]="foundTextTbUbicacionComercialLlegada"
							[virtualScroll]="false"
							[upperCase]="true"
							[required]="true"
							[next]="direccionEntrega"
							[nextIsDisable]="tbNaturalezaCarga"
							[back]="direccionRecojo"
							[backIsDisable]="tbUbicacionComercialEmbarque"
							label="{{ labelPuertoDestino }}">
						</ng-select-simple>
					</div>
					<div class="col-lg-6">
						<input-text #direccionEntrega formControlName="direccionEntrega" [next]="tbNaturalezaCarga" [back]="tbUbicacionComercialLlegada" label="Dirección para entrega"> </input-text>
					</div>
				</div>
			</fieldset>
			<fieldset class="fieldset-border fieldset-focus rounded">
				<legend class="fieldset-border">Mercancias</legend>
				<div class="row">
					<div class="col-lg-3">
						<ng-select-simple
							#tbNaturalezaCarga
							[items]="tbNaturalezaCarga$"
							[virtualScroll]="false"
							[clearable]="true"
							formControlName="tbNaturalezaCarga"
							(keyup.enter)="keyNext()"
							[back]="direccionEntrega"
							[backIsDisable]="tbUbicacionComercialLlegada"
							label="Naturaleza de carga">
						</ng-select-simple>
					</div>
					<div class="col-lg-3">
						<div class="row me-0">
							<div *ngIf="!(isAereo || isCargaSuelta)" class="col-sm-12 pe-0">
								<div class="d-flex align-items-start">
									<div class="container-select-and-add">
										<ng-select-simple
											#sacCotizacionContenedores
											formControlName="sacCotizacionContenedores"
											[items]="sacCotizacionContenedorItems$"
											[next]="cantidadBultos"
											[back]="tbNaturalezaCarga"
											label="Contenedores">
										</ng-select-simple>
									</div>
									<div class="container-button">
										<sc-button
											#addContenedores
											size="sm"
											color="warning"
											(keyup.arrowright)="cantidadBultos.focus()"
											(keyup.arrowleft)="tbNaturalezaCarga.focus()"
											[fill]="true"
											height="31.5px"
											width="34px"
											(onClick)="listadoContenedores()"
											[matTooltip]="'Agregar'"
											matTooltipClass="bg-warning">
											<em class="fas fa-plus px-1"></em>
										</sc-button>
									</div>
								</div>
							</div>
							<div *ngIf="isAereo || isCargaSuelta" class="col-sm-12 pe-0">
								<div class="d-flex align-items-start">
									<div class="container-select-and-add">
										<ng-select-simple
											#sacCotizacionDimensiones
											formControlName="sacCotizacionDimensiones"
											[items]="sacCotizacionDetalleBulto$"
											[next]="cantidadBultos"
											[back]="tbNaturalezaCarga"
											label="Dimensiones">
										</ng-select-simple>
									</div>
									<div class="container-button">
										<sc-button
											#addDimensiones
											size="sm"
											color="warning"
											(keyup.arrowright)="cantidadBultos.focus()"
											(keyup.arrowleft)="tbNaturalezaCarga.focus()"
											[fill]="true"
											height="31.5px"
											width="34px"
											(onClick)="listadoDimensiones()"
											[matTooltip]="'Agregar'"
											matTooltipClass="bg-warning">
											<em class="fas fa-plus px-1"></em>
										</sc-button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-3">
						<input-number
							#cantidadBultos
							formControlName="cantidadBultos"
							[decimals]="0"
							[required]="formGroup.get('tbTipoBulto')?.value"
							[next]="tbTipoBulto"
							(keyup.ArrowUp)="keyNext()"
							label="Bultos">
						</input-number>
					</div>
					<div class="col-lg-3">
						<ng-select-simple
							#tbTipoBulto
							[items]="tbTipoBulto$"
							[virtualScroll]="false"
							formControlName="tbTipoBulto"
							[required]="formGroup.get('cantidadBultos')?.value"
							[clearable]="true"
							[next]="pesoBruto"
							[back]="cantidadBultos"
							label="Tipo de bulto">
						</ng-select-simple>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-3">
						<input-number
							#pesoBruto
							[decimals]="3"
							formControlName="pesoBruto"
							[required]="formGroup.get('tbUnidadMedidaPeso')?.value"
							[next]="tbUnidadMedidaPeso"
							[back]="tbTipoBulto"
							label="Peso bruto">
						</input-number>
					</div>
					<div class="col-lg-3">
						<ng-select-simple
							#tbUnidadMedidaPeso
							[items]="tbUnidadMedidaMasa$"
							formControlName="tbUnidadMedidaPeso"
							[virtualScroll]="false"
							[next]="volumen"
							[required]="formGroup.get('pesoBruto')?.value"
							[clearable]="true"
							[back]="pesoBruto"
							label="Unidad">
						</ng-select-simple>
					</div>
					<div class="col-lg-3">
						<input-number
							#volumen
							[decimals]="3"
							formControlName="volumen"
							[required]="formGroup.get('tbUnidadMedidaVolumen')?.value"
							[next]="tbUnidadMedidaVolumen"
							[back]="tbUnidadMedidaPeso"
							label="{{ labelVolumen }}">
						</input-number>
					</div>
					<div class="col-lg-3">
						<ng-select-simple
							#tbUnidadMedidaVolumen
							[items]="tbUnidadMedidaVolumen$"
							formControlName="tbUnidadMedidaVolumen"
							[required]="formGroup.get('volumen')?.value"
							[virtualScroll]="false"
							[clearable]="true"
							[next]="guardar"
							[nextIsDisable]="cancelar"
							[back]="volumen"
							label="Unidad">
						</ng-select-simple>
					</div>
				</div>
			</fieldset>
		</div>
	</kt-portlet-body>
	<kt-portlet-footer class="py-3">
		<div class="row justify-content-md-between px-4">
			<div class="col-auto d-flex align-items-center ps-0">
				<span class="text-danger">* Obligatorio</span>
			</div>
			<div class="col-auto d-flex align-items-center flex-wrap my-auto">
				<button
					#guardar
					type="button"
					[disabled]="!isFormDifferent() || isLoadingBtnSave"
					(click)="onSubmit()"
					class="btn btn-success me-3"
					[class.kt-spinner]="isLoadingBtnSave"
					[class.kt-spinner--right]="isLoadingBtnSave"
					[class.kt-spinner--md]="isLoadingBtnSave"
					[class.kt-spinner--light]="isLoadingBtnSave">
					Guardar
				</button>
				<button #cancelar type="button" (click)="onCancel()" (keyup.ArrowLeft)="tbUnidadMedidaVolumen.focus()" class="btn btn-danger">Cancelar</button>
			</div>
		</div>
	</kt-portlet-footer>
</kt-portlet>
